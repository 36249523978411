// src/utils/storage.js

// Image extension cache to improve loading performance
export const imageExtensionCache = {
  // Save extension info for a comic and chapter
  saveExtensions: (comicSlug, chapterId, extensionData) => {
    const key = `${comicSlug}-${chapterId}-extensions`;
    localStorage.setItem(key, JSON.stringify(extensionData));
  },

  // Get cached extension info for a comic and chapter
  getExtensions: (comicSlug, chapterId) => {
    const key = `${comicSlug}-${chapterId}-extensions`;
    return JSON.parse(localStorage.getItem(key) || '{}');
  }
};

export const readingProgress = {
    markChapterAsRead: (comicSlug, chapterId) => {
      const progress = JSON.parse(localStorage.getItem(`${comicSlug}-progress`) || '{}');
      progress[chapterId] = true;
      localStorage.setItem(`${comicSlug}-progress`, JSON.stringify(progress));
    },
  
    isChapterRead: (comicSlug, chapterId) => {
      const progress = JSON.parse(localStorage.getItem(`${comicSlug}-progress`) || '{}');
      return progress[chapterId] || false;
    },
  
    getComicProgress: (comicSlug) => {
      return JSON.parse(localStorage.getItem(`${comicSlug}-progress`) || '{}');
    }
  };