// src/pages/ChapterReader.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ContinuousReader } from '../components/reader/ContinuousReader';
import { PageReader } from '../components/reader/PageReader';
import { ReaderHeader } from '../components/reader/ReaderHeader';
import { ReaderFooter } from '../components/reader/ReaderFooter';
import { readingProgress, imageExtensionCache } from '../utils/storage';

// Image loading states for tracking individual image status
const LOADING_STATUS = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

// Generate image paths based on index
const generateImagePath = (basePath, index) => {
  const paddedIndex = String(index + 1).padStart(3, '0');
  return `${basePath}/${paddedIndex}`;
};

export const ChapterReader = () => {
  const { slug, chapterId } = useParams();
  const navigate = useNavigate();
  const [comic, setComic] = useState(null);
  const [chapterPath, setChapterPath] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [imageStatuses, setImageStatuses] = useState({});
  const [imageExtensions, setImageExtensions] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // eslint-disable-next-line
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  // Load comic metadata
  useEffect(() => {
    const loadComic = async () => {
      try {
        const response = await fetch(`/comics/${slug}/info.json`);
        const data = await response.json();
        setComic(data);

        // Determine chapter path
        const path = data.type === 'single' 
          ? `/comics/${slug}/chapters/1`
          : `/comics/${slug}/chapters/${data.chapters[chapterId].slug || chapterId}`;
        
        setChapterPath(path);
        
        // Mark chapter as read
        readingProgress.markChapterAsRead(slug, chapterId);

        // Reset scroll position when changing chapters
        window.scrollTo(0, 0);
      } catch (error) {
        console.error('Failed to load comic:', error);
      }
    };

    loadComic();
  }, [slug, chapterId]);

  // Preload and verify images
  useEffect(() => {
    if (!chapterPath) return;
    
    // Check if we have cached extension data for this comic/chapter
    const cachedExtensions = imageExtensionCache.getExtensions(slug, chapterId);
    if (Object.keys(cachedExtensions).length > 0) {
      setImageExtensions(cachedExtensions);
    }

    // Simplified image verification - we've moved extension detection to the <img> onError handler
    // This just sets up the initial counting and status
    const verifyImage = (index) => {
      return new Promise(resolve => {
        // Update status to pending
        setImageStatuses(prev => ({
          ...prev,
          [index]: LOADING_STATUS.LOADING
        }));
        
        // We assume success now and let the component handle errors
        resolve({ success: true });
      });
    };
    
    // For debugging purposes
    const logImageStatus = (index, status, message) => {
      console.log(`Image ${index}: ${status} - ${message}`);
    };

    const loadImages = async () => {
      // Set an initial conservative page count that will be extended as needed
      let initialPageCount = 30;
      
      // If we have info about the comic, adjust the initial count
      if (comic) {
        if (comic.type === 'single') {
          // Single comics often have fewer pages
          initialPageCount = 20;
        } else if (comic.layout === 'continuous') {
          // Continuous comics often have many pages, but we only load a subset initially
          initialPageCount = 50;
        }
      }
      
      console.log(`Setting initial page count to ${initialPageCount}`);
      setTotalPages(initialPageCount);
      setIsLoadingComplete(true);
    };

    loadImages();
  }, [chapterPath]);

  // Handle chapter navigation
  const navigateToChapter = (chapterId) => {
    if (chapterId) {
      navigate(`/comic/${slug}/chapter/${chapterId}`);
      window.scrollTo(0, 0);
    }
  };

  // Early render of UI while images load
  if (!comic) return null;

  // Find previous and next chapters
  const chapterIds = Object.keys(comic.chapters || {}).sort((a, b) => Number(a) - Number(b));
  const currentIndex = chapterIds.indexOf(chapterId);
  const previousChapter = currentIndex > 0 ? chapterIds[currentIndex - 1] : null;
  const nextChapter = currentIndex < chapterIds.length - 1 ? chapterIds[currentIndex + 1] : null;

  // Get current chapter
  const chapter = comic.type === 'single' 
    ? { title: comic.title } 
    : comic.chapters[chapterId];

  // Generate array of valid image paths
  const images = Array.from({ length: totalPages }, (_, i) => {
    // Just pass the base path without extension - SmartImage will try extensions
    return {
      src: generateImagePath(chapterPath, i),
      status: imageStatuses[i] || LOADING_STATUS.PENDING
    };
  });

  // Handler to dynamically update the page count when needed
  const handleUpdatePageCount = (newCount) => {
    if (newCount > totalPages) {
      console.log(`Updating page count from ${totalPages} to ${newCount}`);
      setTotalPages(newCount);
    }
  };

  const ReaderComponent = comic.layout === 'continuous' ? ContinuousReader : PageReader;

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <ReaderHeader 
        comic={comic}
        chapter={chapter}
        onToggleMenu={() => setIsMenuOpen(!isMenuOpen)}
      />

      <main className="pt-16 pb-16">
        <ReaderComponent 
          images={images}
          onUpdatePageCount={handleUpdatePageCount}
          onPageChange={(page) => {
            // We don't need complex preloading anymore
            // since SmartImage components handle loading automatically
            // and ContinuousReader manages progressive loading
          }}
        />
      </main>

      <ReaderFooter
        comic={comic}
        currentChapter={chapterId}
        previousChapter={previousChapter}
        nextChapter={nextChapter}
        onPreviousChapter={() => navigateToChapter(previousChapter)}
        onNextChapter={() => navigateToChapter(nextChapter)}
      />
    </div>
  );
};