// src/components/reader/ContinuousReader.jsx
import React, { useState, useEffect, useRef } from 'react';

// Component to try loading an image with multiple extensions
const SmartImage = ({ baseSrc, alt, className, loading, onLoad }) => {
  const [currentExtension, setCurrentExtension] = useState('jpg');
  const [loadFailed, setLoadFailed] = useState(false);
  const extensionsToTry = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
  const [extensionIndex, setExtensionIndex] = useState(0);
  
  // Important: baseSrc may already include the extension
  const basePathWithoutExtension = baseSrc.replace(/\.[^/.]+$/, '');
  
  // Create the full source URL with current extension
  const fullSrc = `${basePathWithoutExtension}.${currentExtension}`;
  
  // For debugging
  console.log('Trying to load:', fullSrc);
  
  const handleError = () => {
    // Try the next extension
    const nextIndex = extensionIndex + 1;
    if (nextIndex < extensionsToTry.length) {
      setExtensionIndex(nextIndex);
      setCurrentExtension(extensionsToTry[nextIndex]);
    } else {
      // We've tried all extensions
      setLoadFailed(true);
      if (onLoad) onLoad();
    }
  };
  
  if (loadFailed) {
    return (
      <div className="flex items-center justify-center h-40 bg-gray-800">
        <span className="text-red-400">Failed to load image</span>
      </div>
    );
  }
  
  return (
    <img
      src={fullSrc}
      alt={alt}
      className={className}
      loading={loading}
      onLoad={onLoad}
      onError={handleError}
    />
  );
};

export const ContinuousReader = ({ images, onUpdatePageCount }) => {
  const containerRef = useRef(null);
  const [visibleImages, setVisibleImages] = useState([]);
  const [loadedCount, setLoadedCount] = useState(0);
  
  // Initialize with first batch of images
  useEffect(() => {
    // Initially load just the first 10 images for faster display
    const initialBatchSize = 10;
    console.log(`Loading initial batch of ${initialBatchSize} images out of ${images.length}`);
    setVisibleImages(images.slice(0, Math.min(initialBatchSize, images.length)));
    setLoadedCount(0); // Reset loaded count when images array changes
  }, [images]);
  
  // Load more images as previous ones finish loading
  useEffect(() => {
    // If we've loaded at least 50% of the current batch and there are more images to load
    if (loadedCount >= visibleImages.length * 0.5 && visibleImages.length < images.length) {
      // Load next batch of images (10 more)
      const nextBatchSize = 10;
      const nextBatch = images.slice(visibleImages.length, 
        Math.min(visibleImages.length + nextBatchSize, images.length));
      
      console.log(`Loading next batch of ${nextBatch.length} images, ${visibleImages.length} already loaded`);
      setVisibleImages(prev => [...prev, ...nextBatch]);
    }
    
    // If we've loaded enough images and we're getting close to the end,
    // check if we need to update the total page count
    if (loadedCount > visibleImages.length * 0.7 && 
        visibleImages.length >= images.length * 0.8 && 
        onUpdatePageCount) {
      // Suggest loading more images if we're getting close to the end
      const newCount = images.length + 20;
      console.log(`Requesting more images, increasing count from ${images.length} to ${newCount}`);
      onUpdatePageCount(newCount);
    }
  }, [loadedCount, visibleImages.length, images, onUpdatePageCount]);

  return (
    <div 
      ref={containerRef}
      className="max-w-5xl mx-auto space-y-0"
    >
      {visibleImages.map((image, index) => (
        <div 
          key={index}
          className="relative min-h-[200px]"
        >
          <SmartImage 
            baseSrc={image.src} 
            alt={`Page ${index + 1}`}
            className="w-full h-auto"
            loading={index === 0 ? "eager" : "lazy"}
            onLoad={() => setLoadedCount(prev => prev + 1)}
          />
        </div>
      ))}
    </div>
  );
};